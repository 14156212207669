import React from 'react'
import { useIsCanada } from '../../../features/account/features/franchise-membership/hooks/useHost/useIsCanada'
import { FranchiseCountryEnum } from '../../../generated/graphql'

export function useCountryAvailability() {
  const isCanada = useIsCanada()
  return React.useMemo(() => {
    return { countryAvailability: [isCanada ? FranchiseCountryEnum.CA : FranchiseCountryEnum.US] }
  }, [isCanada])
}
